import React, { useState, useEffect } from 'react';
import _ from 'underscore';
var QRCode = require('qrcode');

import { Dialog } from '@reach/dialog';
import { Loading } from "../../components/Loading";
import {
  addTechCertsToShopAccount,
} from '../../libs/db-lib';
import { Text, TextOnly } from '../../components/Text';
import LoaderButton from '../../components/LoaderButton';

import 'react-table/react-table.css';
import { toast } from 'react-toastify';
import { useUser } from '../../context/User';
import { Shop } from '../../types';
import AlertModal from '../../components/AlertModal';
import BasicModal from '../../components/BasicModal';

interface AddTechCertificationProps {
  perTechCertRate: number;
  currentShop: Shop;
  fetchUser: Function;
}

const AddTechCertification: React.FC<AddTechCertificationProps> = (props) => {

  const { user, currentShop } = useUser();
  const [numToAdd, setNumToAdd] = useState('');
  const [showAddTechCerts, setShowAddTechCerts] = useState(false);
  const [isLoadingAddTechCerts, setIsLoadingAddTechCerts] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const alertTitle = TextOnly('error');

  const handleNumToAddChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const target = event.currentTarget;
    if (target.value !== numToAdd) {
      setNumToAdd(target.value);
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setShowAddTechCerts(false);
    setShowAlertModal(false);
  }

  const handleAddTechCertsButton = (event: React.MouseEvent<HTMLButtonElement>) => {

    const hasLastFour = !!props.currentShop?.shopPaymentInfo?.last4 || null;

    if (!hasLastFour) {
      setShowModal(true);
    } else {
      setShowAddTechCerts(true);
    }
  }

  const modalBody = (handleCloseModal: Function) => {
    const handleConfirm = async () => {
      setIsLoadingAddTechCerts(true);

      let ret = await addTechCertsToShopAccount(
        currentShop?.shopID,
        numToAdd
      );
      if (ret?.error) {
        setIsLoadingAddTechCerts(false);
        setAlertMessage(ret.error);
        setShowAlertModal(true);
      } else {
        await props.fetchUser();
        setIsLoadingAddTechCerts(false);
        handleCloseModal();

        const numAdded = numToAdd;
        toast.success(
          `${TextOnly('techCertsAddedToShop', {'num': numAdded})} ${TextOnly(
            'youHaveBeenCharged'
          )} $${ret.payment} ${TextOnly('today')}.`,
          { containerId: 'standard' }
        );
      }
    }

    return (
      <>
        <div className="c-modal__body">
          <p>
            <Text tid="areYouSureYouWantToAddTechCerts" sub={{num: <span>{numToAdd}</span>}} />
          </p>
          <p>
            <Text tid="youWillBeCharged" /> $
            {(parseInt(numToAdd) * props.perTechCertRate).toFixed(2)}/
            <Text tid="year" /> <Text tid="proratedPaymentExplain" />
          </p>
          <LoaderButton
            type="button"
            isLoading={isLoadingAddTechCerts}
            text={TextOnly('confirm')}
            loadingText={TextOnly('savingChanges')}
            onClick={handleConfirm}
          />
        </div>
      </>
    )
  }


  return (
    <>
      <div className="c-select">
        <select
          id="addUsers"
          onChange={handleNumToAddChange}
          value={numToAdd || 'default'}
        >
          <option key="default" value="default" disabled>
            {TextOnly("addShopTechCerts")}
          </option>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30,
              40, 50, 60, 80, 100,
              ].map((num) => {
                  let rate = num * props.perTechCertRate;
                  const rateTxt = rate.toFixed(2);
                  return (
                    <option key={num} value={num.toString()}>
                      {num} (+${rateTxt}/{TextOnly('year')})
                    </option>
                  );
                })}
        </select>
      </div>

      <div className="c-field u-margin-top-large">
        <LoaderButton
          id="addTechCertifications"
          className="c-btn-full"
          type="button"
          isLoading={isLoadingAddTechCerts}
          disabled={numToAdd === ''}
          text={TextOnly('addShopTechCerts')}
          loadingText={TextOnly('addingShopTechCerts')}
          onClick={handleAddTechCertsButton.bind(this)}
        />
      </div>

      <AlertModal
        title={TextOnly('noPaymentMethodAvailable')}
        message={
          <p>
            <Text tid="noPaymentMethodForIncreasingTechCerts" />
          </p>
        }
        showModal={showModal}
        size="small"
        handleCancel={handleCancel}
      />

      <BasicModal
        isOpen={showAddTechCerts}
        handleCancelModal={handleCancel}
        title={TextOnly("confirmAddTechCerts")}
        className="c-modal"
        isLoading={isLoadingAddTechCerts}
        bodyContent={modalBody}
      />

      <AlertModal
        title={alertTitle}
        message={alertMessage}
        showModal={showAlertModal}
        size="small"
        handleCancel={handleCancel}
      />
    </>

  );
}

export default AddTechCertification;
