import React, { useState, useEffect } from 'react';
import { SHOP_TYPES } from '../CONSTANTS';
import { Shop, User, ShopType } from '../types';
import { Text, TextOnly } from './Text';
import { getSystemConfig } from '../libs/db-lib';
import AlertModal from './AlertModal';
import { Loading } from './Loading';

interface PricingTableProps {
  handleChangePlan: (plan: string) => void;
  handleLearnMore: () => void;
  handleCrmLearnMore: () => void;
  shopType?: ShopType;
  user?: User;
  currentShop: Shop;
}

export function PricingTable({
  handleChangePlan,
  handleLearnMore,
  handleCrmLearnMore,
  shopType,
  user,
  currentShop,
}: PricingTableProps) {
  const isCurrentShopStandard = shopType === SHOP_TYPES.STANDARD;
  const isCurrentShopPlus = shopType === SHOP_TYPES.PLUS;
  const [config, setConfig] = useState<any>(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState<any>(null);
  const [showDesc, setShowDesc] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getConfig = async () => {
      let response = await getSystemConfig();
      setConfig(response);
      setIsLoading(false);
    };

    getConfig();
  }, []);

  let standardMonthly, plusMonthly, crmMonthly;
  if (config) {
    standardMonthly = Math.round((config.prices.standardShop + Number.EPSILON) * 100 / 12) / 100;
    plusMonthly = Math.round((config.prices.plusShop + Number.EPSILON) * 100 / 12) / 100;
    crmMonthly = config.prices?.crmBasicMonthly?.toFixed(2);
  }

  return ( isLoading ? <Loading /> :
    <>
      <div className="u-padding-large pt-aux parent-element">
        <table className="c-pricing-table-medium c-pricing-table-mobile--medium">
          <colgroup>
            <col />
            <col />
            <col className="c-pricing-table__column--highlight" />
            <col className="c-pricing-table__column--highlight-crm" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th className="l-container-center">
                <button
                  className="c-btn-icon"
                  onClick={() => {
                    setTitle(TextOnly('comparePlans'));
                    setDescription(
                      <>
                        <Text
                          tid="autoAuthStandardOption"
                          sub={{
                            AutoAuthStandard: (
                              <strong>AutoAuth Standard</strong>
                            ),
                          }}
                        />
                        <br />
                        <br />
                        <Text
                          tid="autoAuthPlusOption"
                          sub={{
                            AutoAuthStandardPlus: (
                              <strong>
                                AutoAuth Plus{' '}
                                <span className="emphasis">
                                  ({TextOnly('recommended')})
                                </span>
                              </strong>
                            ),
                          }}
                        />
                        <br />
                        <br />
                        <Text
                          tid="autoAuthEnterpriseOption"
                          sub={{
                            AutoAuthEnterprise: (
                              <strong>AutoAuth Enterprise Pro</strong>
                            ),
                          }}
                        />
                      </>
                    );
                    setShowDesc(true);
                  }}
                >
                  <div className="c-btn__inner">
                    <i className="c-btn__icon fal fa-question-circle" />
                  </div>
                </button>
              </th>
              <th className="h2">
                <Text tid="autoauthStandard" />
              </th>
              <th className="h2 c-pricing-table__heading--highlight c-pricing-table__extend--top c-pricing-table__extend">
                <Text tid="autoauthStandardPlus" />
              </th>
              <th className="h2 c-pricing-table__heading--highlight-crm c-pricing-table__extend-crm--top c-pricing-table__extend">
                AutoAuth PLUS CRM
              </th>
              <th className="h2">
                <Text tid="autoauthEnterprisePro" />
              </th>
            </tr>
            <tr>
              <th className="c-pricing-table__heading">

              </th>
              <td className="c-pricing-table__detail">
                <p className='p-pricing-table'>
                  <span>{TextOnly('aasoShort')}</span>
                </p>
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight">
                <p className='p-pricing-table'>
                <span>{TextOnly('aapoShort')}</span>
                </p>
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm">
                <p className='p-pricing-table'>
                <span>{TextOnly('aapcoShort')}</span>
                </p>
              </td>
              <td className="c-pricing-table__detail">
                <p className='p-pricing-table'>
                <span>{TextOnly('aaeoShort')}</span>
                </p>
              </td>
            </tr>
            <tr>
              <th className="c-pricing-table__heading">
                <Text tid="toolsPerShop" />
              </th>
              <td className="c-pricing-table__detail">
                {config && config.shopBaseValues?.standardBaseTools}
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight">
                {config && config.shopBaseValues?.plusBaseTools}
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm">
                {config && config.shopBaseValues?.plusBaseTools}
              </td>
              <td className="c-pricing-table__detail">
                <Text tid="unlimitedTools" />
              </td>
            </tr>
            <tr>
              <th className="c-pricing-table__heading">
                <Text tid="usersPerShop" />
              </th>
              <td className="c-pricing-table__detail">
                {config && config.shopBaseValues?.standardBaseUsers}
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight">
                {config && config.shopBaseValues?.plusBaseTools}
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm">
                {config && config.shopBaseValues?.plusBaseTools}
              </td>
              <td className="c-pricing-table__detail">
                <Text tid="unlimitedUsers" />
              </td>
            </tr>
            <tr>
              <th className="c-pricing-table__heading">
                <Text tid="certifiedTechsPerShop" />
              </th>
              <td className="c-pricing-table__detail">{config.shopBaseValues?.standardBaseTechCerts}</td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight">
                {config.shopBaseValues?.plusBaseTechCerts}
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm">
                {config.shopBaseValues?.plusBaseTechCerts}
              </td>
              <td className="c-pricing-table__detail">0</td>
            </tr>
            <tr>
              <th className="c-pricing-table__heading">
                <Text tid="oemsPerShop" />
              </th>
              <td className="c-pricing-table__detail">
                {config && config.shopBaseValues?.standardBaseOEMRegions}
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight">
                {config && config.shopBaseValues?.plusBaseOEMRegions}
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm">
                {config && config.shopBaseValues?.plusBaseOEMRegions}
              </td>
              <td className="c-pricing-table__detail">
                {config && config.shopBaseValues?.plusBaseOEMRegions}
              </td>
            </tr>
            <tr>
              <th className="c-pricing-table__heading">
                <Text tid="customNamesForTools" />
              </th>
              <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </td>
              <td className="c-pricing-table__detail">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </td>
            </tr>
            <tr>
              <th className="c-pricing-table__heading">
                <Text tid="customNotesForTools" />
              </th>
              <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </td>
              <td className="c-pricing-table__detail">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </td>
            </tr>
            <tr>
              <th className="c-pricing-table__heading">
                <Text tid="shopHistoryReports" />
              </th>
              <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </td>
              <td className="c-pricing-table__detail">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </td>
            </tr>
            <tr>
              <th className="c-pricing-table__heading">
                <Text tid="technicianHistoryReports" />
              </th>
              <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </td>
              <td className="c-pricing-table__detail">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </td>
            </tr>
            <tr>
              <th className="c-pricing-table__heading">
                <Text tid="toolHistoryReports" />
              </th>
              <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </td>
              <td className="c-pricing-table__detail">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </td>
            </tr>
            <tr>
              <th className="c-pricing-table__heading">
                <Text tid="customerManagement" />
              </th>
              <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
            </tr>
            <tr>
              <th className="c-pricing-table__heading">
                <Text tid="vehicleManagement" />
              </th>
              <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
            </tr>
            <tr>
              <th className="c-pricing-table__heading">
                <Text tid="serviceOrdersManagement" />
              </th>
              <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
            </tr>
            <tr>
              <th className="c-pricing-table__heading">
                <Text tid="appointmentCalendar" />
              </th>
              <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
            </tr>
            <tr>
              <th className="c-pricing-table__heading">
                <Text tid="bulkToolImport" />
              </th>
              <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
              <td className="c-pricing-table__detail">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </td>
            </tr>
            <tr>
              <th className="c-pricing-table__heading">
                <Text tid="bulkTechnicianImport" />
              </th>
              <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
              <td className="c-pricing-table__detail">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </td>
            </tr>
            <tr>
              <th className="c-pricing-table__heading">
                <Text tid="identityManagementSystem" />
              </th>
              <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm c-pricing-table__detail--disabled">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </td>
              <td className="c-pricing-table__detail">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </td>
            </tr>

            <tr>
              <th></th>
              <td className="c-pricing-table__detail c-pricing-table__detail--big">
                {config && `$${standardMonthly}`}/<Text tid="month" /><br />
                <div className="c-pricing-table__detail--small ">
                  ({config && `$${config.prices.standardShop}.00`} <Text tid="billedYearly" />)
                </div>
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight c-pricing-table__detail--big">
                {config && `$${plusMonthly}`}/<Text tid="month" /><br />
                <div className="c-pricing-table__detail--small ">
                  ({config && `$${config.prices.plusShop}.00`} <Text tid="billedYearly" />)
                </div>
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm c-pricing-table__detail--big">
                {config && `$${plusMonthly}`}/<Text tid="month" /><br />
                <div className="c-pricing-table__detail--small ">
                  ({config && `$${config.prices.plusShop}.00`} <Text tid="billedYearly" />)
                </div>
                <div>+</div>
                <div>
                  <Text tid="crmAddon" />
                </div>
                <div>
                  {config && `$${crmMonthly}`}/<Text tid="month" />
                </div>
                <div className="c-pricing-table__detail--small c-pricing-table-padding-bottom">
                  {!currentShop?.crmFreeTrialUsed ?
                    <>
                      (<Text tid="afterFreeTrial" />)
                    </>
                  : ''}
                </div>
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--big u-blur">
                $XXX.XX/
                <Text tid="year" />
              </td>
            </tr>
            <tr>
              <th></th>
              <td className="c-pricing-table__detail c-pricing-table__detail--small">
                <Text tid="addlOems" />{' '}
                {config && `$${config.prices.oemRegionAddon}.00`}/
                <Text tid="year" />
                <br />
                <Text tid="addlCertTechs" />{' '}
                {config && `$${config.prices.standardTechCertAddon}`}/
                <Text tid="year" />
                <br />
                <br />
                <br />

                (<Text tid="maxUsers" />
                {config && config.shopMaxValues.standardMaxUsers})
                <br />
                (<Text tid="maxTools" />
                {config && config.shopMaxValues.standardMaxTools})
              </td>
              <td className="c-pricing-table__detail   c-pricing-table__detail--small c-pricing-table__detail--highlight">
                <Text tid="addlOems" />{' '}
                {config && `$${config.prices.oemRegionAddon}.00`}/
                <Text tid="year" />
                <br />
                <Text tid="addlCertTechs" />{' '}
                {config && `$${config.prices.plusTechCertAddon}`}/
                <Text tid="year" />
                <br />
                <Text tid="addlUsers" />{' '}
                {config && `$${config.prices.plusUserAddon}.00`}/
                <Text tid="user" />/<Text tid="year" />
                <br />
                (<Text tid="extraToolForEachAddlUser" />)
                <br />
                (<Text tid="maxUsers" />
                {config && config.shopMaxValues.plusMaxUsers})
                <br />
                (<Text tid="maxTools" />
                {config && config.shopMaxValues.plusMaxTools})
                <div>
                  <button
                    className="c-btn-medium u-margin-auto u-margin-top-large"
                    onClick={handleLearnMore}
                  >
                    <div className="c-btn__inner">
                      <Text tid="learnMore" />
                    </div>
                  </button>
                </div>
              </td>
              <td className="c-pricing-table__detail   c-pricing-table__detail--small c-pricing-table__detail--highlight-crm">
                <Text tid="addlOems" />{' '}
                {config && `$${config.prices.oemRegionAddon}.00`}/
                <Text tid="year" />
                <br />
                <Text tid="addlCertTechs" />{' '}
                {config && `$${config.prices.standardTechCertAddon}`}/
                <Text tid="year" />
                <br />
                <Text tid="addlUsers" />{' '}
                {config && `$${config.prices.plusUserAddon}.00`}/
                <Text tid="user" />/<Text tid="year" />
                <br />
                (<Text tid="extraToolForEachAddlUser" />)
                <br />
                (<Text tid="maxUsers" />
                {config && config.shopMaxValues.plusMaxUsers})
                <br />
                (<Text tid="maxTools" />
                {config && config.shopMaxValues.plusMaxTools})
                <div>
                  <button
                    className="c-btn-medium u-margin-auto u-margin-top-large"
                    onClick={handleCrmLearnMore}
                  >
                    <div className="c-btn__inner">
                      <Text tid="learnMore" />
                    </div>
                  </button>
                </div>
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--small u-blur">
                *$XX.XX/
                <Text tid="user" />/<Text tid="year" />
                <br />
                *$XX.XX/
                <Text tid="registeredTool" />/<Text tid="year" />
              </td>
            </tr>
            <tr>
              <th></th>
              <td className="c-pricing-table__detail">
                <div className="c-pricing-table__footer">
                  { (user || shopType) &&
                    <button
                      className="c-btn-full c-pricing-table__btn"
                      onClick={() => handleChangePlan(SHOP_TYPES.STANDARD)}
                      disabled={isCurrentShopStandard || isCurrentShopPlus}
                    >
                      <div className="c-btn__inner">
                        { isCurrentShopStandard ?
                          <>
                            <div>AutoAuth STANDARD</div>
                            <br />
                            <span className='white-space-nowrap'>
                              (<Text tid="currentPlan" />)
                            </span>
                          </>
                          :
                          <span className='white-space-nowrap'>
                            <Text tid="chooseStandard" />
                          </span>
                        }
                      </div>
                    </button>
                  }
                  <Text tid="creditCardOnly" />
                </div>
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight c-pricing-table__extend--bottom c-pricing-table__extend">
                <div className="c-pricing-table__footer c-pricing-table__footer--highlight">
                  {(user || shopType) &&
                    <button
                      className="c-btn-full c-pricing-table__btn--highlight"
                      onClick={() => handleChangePlan(SHOP_TYPES.PLUS)}
                      disabled={isCurrentShopPlus}
                    >
                      <div className="c-btn__inner flex-flow-column">
                      { isCurrentShopPlus ?
                        <>
                          <div>AutoAuth PLUS</div>
                          <span className='white-space-nowrap'>
                            (<Text tid="currentPlan" />)
                          </span>
                        </>
                        :
                        <span className='white-space-nowrap'>
                          <Text tid="chooseStandardPlus" />
                        </span>
                      }
                      </div>
                    </button>
                  }
                  <Text tid="creditCardOnly" />
                </div>
              </td>
              <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm c-pricing-table__extend-crm--bottom c-pricing-table__extend">
                <div className="c-pricing-table__footer c-pricing-table__footer--highlight">
                  {(user || shopType) &&
                    <button
                      className="c-btn-full c-pricing-table__btn--highlight"
                      onClick={() => handleChangePlan('PLUSCRM')}
                    >
                      <div className="c-btn__inner flex-flow-column">
                        <Text tid="choosePlusCrm" />
                        <span className='white-space-nowrap'>
                          (<Text tid="recommended" />)
                        </span>
                      </div>
                    </button>
                  }
                  <Text tid="creditCardOnly" />
                </div>
              </td>
              <td className="c-pricing-table__detail">
                <div className="c-pricing-table__footer">
                  <a
                    className="c-btn-full c-pricing-table__btn"
                    href="https://info.autoauth.com/contact/"
                  >
                    <div className="c-btn__inner">
                      <Text tid="contactUs" />
                    </div>
                  </a>
                  <Text tid="purchaseOrderOnly" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Mobile (Card) View Only */}
      <div className="l-container-full-width u-mobile-only c-pricing-table-mobile--small pt-aux parent-element">
        <div className="c-pricing-table__wrapper">
          {/* AutoAuth PLUS CRM */}
          <div className="l-container-center l-container-large u-margin-top-8">
            <div className="c-card c-pricing-table-card c-pricing-table__column--highlight-crm">
              <h2 className="u-text-center l-container-center c-pricing-table__heading--highlight-crm">
                AutoAuth PLUS CRM
                <button
                  className="c-btn-icon--highlight u-margin-right"
                  onClick={() => {
                    setTitle('AutoAuth PLUS CRM');
                    setDescription(
                      <>
                        <Text
                          tid="autoAuthPlusCRMOption"
                          sub={{
                            AutoAuthStandardPlusCRM: (
                              <strong>AutoAuth PLUS CRM</strong>
                            ),
                          }}
                        />
                      </>
                    );
                    setShowDesc(true);
                  }}
                >
                  <div className="c-btn__inner">
                    <i className="c-btn__icon fal fa-question-circle" />
                  </div>
                </button>
              </h2>
              <div className="c-card__description">
                <table className="c-pricing-table--highlight">
                  <colgroup>
                    <col className="c-pricing-table__column" />
                    <col className="c-pricing-table__column" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight-crm">
                        <Text tid="toolsPerShop" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm">
                        {config && config.shopBaseValues?.plusBaseTools}
                      </td>
                    </tr>
                    <tr>
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight-crm">
                        <Text tid="usersPerShop" />
                      </th>
                      <td className="c-pricing-table__detail  c-pricing-table__detail--highlight-crm">
                        {config && config.shopBaseValues?.plusBaseUsers - 1}
                      </td>
                    </tr>
                    <tr>
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight-crm">
                        <Text tid="adminsPerShop" />
                      </th>
                      <td className="c-pricing-table__detail  c-pricing-table__detail--highlight-crm">
                        1
                      </td>
                    </tr>
                    <tr>
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight-crm">
                        <Text tid="oemsPerShop" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm">
                        {config && config.shopBaseValues?.plusBaseOEMRegions}
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight-crm">
                        <Text tid="customNamesForTools" />
                      </th>
                      <td className="c-pricing-table__detail  c-pricing-table__detail--highlight-crm">
                        <i
                          className="fal fa-check-circle"
                          title="fa-check-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight-crm">
                        <Text tid="customNotesForTools" />
                      </th>
                      <td className="c-pricing-table__detail  c-pricing-table__detail--highlight-crm">
                        <i
                          className="fal fa-check-circle"
                          title="fa-check-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight-crm">
                        <Text tid="shopHistoryReports" />
                      </th>
                      <td className="c-pricing-table__detail  c-pricing-table__detail--highlight-crm">
                        <i
                          className="fal fa-check-circle"
                          title="fa-check-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight-crm">
                        <Text tid="technicianHistoryReports" />
                      </th>
                      <td className="c-pricing-table__detail  c-pricing-table__detail--highlight-crm">
                        <i
                          className="fal fa-check-circle"
                          title="fa-check-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight-crm">
                        <Text tid="toolHistoryReports" />
                      </th>
                      <td className="c-pricing-table__detail  c-pricing-table__detail--highlight-crm">
                        <i
                          className="fal fa-check-circle"
                          title="fa-check-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight-crm">
                        <Text tid="customerManagement" />
                      </th>
                      <td className="c-pricing-table__detail  c-pricing-table__detail--highlight-crm">
                        <i
                          className="fal fa-check-circle"
                          title="fa-check-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight-crm">
                        <Text tid="vehicleManagement" />
                      </th>
                      <td className="c-pricing-table__detail  c-pricing-table__detail--highlight-crm">
                        <i
                          className="fal fa-check-circle"
                          title="fa-check-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight-crm">
                        <Text tid="serviceOrdersManagement" />
                      </th>
                      <td className="c-pricing-table__detail  c-pricing-table__detail--highlight-crm">
                        <i
                          className="fal fa-check-circle"
                          title="fa-check-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight-crm">
                        <Text tid="appointmentCalendar" />
                      </th>
                      <td className="c-pricing-table__detail  c-pricing-table__detail--highlight-crm">
                        <i
                          className="fal fa-check-circle"
                          title="fa-check-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight-crm">
                        <Text tid="bulkToolImport" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight-crm">
                        <Text tid="bulkTechnicianImport" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--highlight-crm c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="c-pricing-table__detail c-pricing-table__detail--highlight-crm c-pricing-table__detail--big">
                {config && `$${plusMonthly}`}/<Text tid="month" /><br />
                <div className="c-pricing-table__detail--small ">
                  ({config && `$${config.prices.plusShop}.00`} <Text tid="billedYearly" />)
                </div>
                <div>+</div>
                <div>
                  <Text tid="crmAddon" />
                </div>
                <div>
                  {config && `$${crmMonthly}`}/<Text tid="month" />
                </div>
                <div className="c-pricing-table__detail--small c-pricing-table-padding-bottom">
                  {!currentShop?.crmFreeTrialUsed ?
                    <>
                      (<Text tid="afterFreeTrial" />)
                    </>
                  : ''}
                </div>
              </div>

              <div className="c-pricing-table__detail  c-pricing-table__detail--small c-pricing-table__detail--highlight-crm">
                <Text tid="addlOems" />{' '}
                {config && `$${config.prices.oemRegionAddon}.00`}/
                <Text tid="year" />
                <br />
                <Text tid="addlUsers" />{' '}
                {config && `$${config.prices.plusUserAddon}.00`}/
                <Text tid="user" />/<Text tid="year" />
                <br />
                (<Text tid="extraToolForEachAddlUser" />)
                <br />
                (<Text tid="maxUsers" />
                {config && config.shopMaxValues.plusMaxUsers})
                <br />
                (<Text tid="maxTools" />
                {config && config.shopMaxValues.plusMaxTools})
                <div>
                  <button
                    className="c-btn-medium u-margin-auto u-margin-top-large"
                    onClick={handleCrmLearnMore}
                  >
                    <div className="c-btn__inner">
                      <Text tid="learnMore" />
                    </div>
                  </button>
                </div>
              </div>
              <div className="c-pricing-table__footer c-pricing-table__footer--highlight">
                {(user || shopType) &&
                  <a
                    className="c-btn-full c-pricing-table__btn--highlight"
                    href="#/"
                    onClick={() => handleChangePlan('PLUSCRM')}
                  >
                    <div className="c-btn__inner">
                      <Text tid="choosePlusCrm" /><br />
                      (<Text tid="recommended" />)
                    </div>
                  </a>
                }
                <Text tid="creditCardOnly" />
              </div>
            </div>
          </div>
          <br />
          <div className="l-container-full-width u-mobile-only c-pricing-table-mobile--small pt-aux parent-element">
          {/* AutoAuth PLUS */}
            <div className="c-card c-pricing-table-card c-pricing-table__column--highlight">
              <h2 className="u-text-center l-container-center c-pricing-table__heading--highlight">
                <Text tid="autoauthStandardPlus" />
                <button
                  className="c-btn-icon--highlight u-margin-right"
                  onClick={() => {
                    setTitle('AutoAuth Plus');
                    setDescription(
                      <>
                        <Text
                          tid="autoAuthPlusOption"
                          sub={{
                            AutoAuthStandardPlus: (
                              <strong>AutoAuth Plus</strong>
                            ),
                          }}
                        />
                      </>
                    );
                    setShowDesc(true);
                  }}
                >
                  <div className="c-btn__inner">
                    <i className="c-btn__icon fal fa-question-circle" />
                  </div>
                </button>
              </h2>
              <div className="c-card__description">
                <table className="c-pricing-table--highlight">
                  <colgroup>
                    <col className="c-pricing-table__column" />
                    <col className="c-pricing-table__column" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight c-pricing-table__heading--highlight">
                        <Text tid="toolsPerShop" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--highlight">
                        {config && config.shopBaseValues?.plusBaseTools}
                      </td>
                    </tr>
                    <tr>
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight">
                        <Text tid="usersPerShop" />
                      </th>
                      <td className="c-pricing-table__detail  c-pricing-table__detail--highlight">
                        {config && config.shopBaseValues?.plusBaseUsers - 1}
                      </td>
                    </tr>
                    <tr>
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight">
                        <Text tid="adminsPerShop" />
                      </th>
                      <td className="c-pricing-table__detail  c-pricing-table__detail--highlight">
                        1
                      </td>
                    </tr>
                    <tr>
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight c-pricing-table__heading--highlight">
                        <Text tid="oemsPerShop" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--highlight">
                        {config && config.shopBaseValues?.plusBaseOEMRegions}
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight">
                        <Text tid="customNamesForTools" />
                      </th>
                      <td className="c-pricing-table__detail  c-pricing-table__detail--highlight">
                        <i
                          className="fal fa-check-circle"
                          title="fa-check-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight">
                        <Text tid="customNotesForTools" />
                      </th>
                      <td className="c-pricing-table__detail  c-pricing-table__detail--highlight">
                        <i
                          className="fal fa-check-circle"
                          title="fa-check-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight">
                        <Text tid="shopHistoryReports" />
                      </th>
                      <td className="c-pricing-table__detail  c-pricing-table__detail--highlight">
                        <i
                          className="fal fa-check-circle"
                          title="fa-check-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight">
                        <Text tid="technicianHistoryReports" />
                      </th>
                      <td className="c-pricing-table__detail  c-pricing-table__detail--highlight">
                        <i
                          className="fal fa-check-circle"
                          title="fa-check-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight">
                        <Text tid="toolHistoryReports" />
                      </th>
                      <td className="c-pricing-table__detail  c-pricing-table__detail--highlight">
                        <i
                          className="fal fa-check-circle"
                          title="fa-check-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight">
                        <Text tid="customerManagement" />
                      </th>
                      <td className="c-pricing-table__detail  c-pricing-table__detail--highlight c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight">
                        <Text tid="vehicleManagement" />
                      </th>
                      <td className="c-pricing-table__detail  c-pricing-table__detail--highlight c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight">
                        <Text tid="serviceOrdersManagement" />
                      </th>
                      <td className="c-pricing-table__detail  c-pricing-table__detail--highlight c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight">
                        <Text tid="appointmentCalendar" />
                      </th>
                      <td className="c-pricing-table__detail  c-pricing-table__detail--highlight c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight">
                        <Text tid="bulkToolImport" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--highlight c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading c-pricing-table__heading--highlight">
                        <Text tid="bulkTechnicianImport" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--highlight c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="c-pricing-table__detail c-pricing-table__detail--highlight c-pricing-table__detail--big">
                {config && `$${plusMonthly}`}/<Text tid="month" /><br />
                <div className="c-pricing-table__detail--small ">
                  ({config && `$${config.prices.plusShop}.00`} <Text tid="billedYearly" />)
                </div>
              </div>

              <div className="c-pricing-table__detail  c-pricing-table__detail--small c-pricing-table__detail--highlight">
                <Text tid="addlOems" />{' '}
                {config && `$${config.prices.oemRegionAddon}.00`}/
                <Text tid="year" />
                <br />
                <Text tid="addlUsers" />{' '}
                {config && `$${config.prices.plusUserAddon}.00`}/
                <Text tid="user" />/<Text tid="year" />
                <br />
                (<Text tid="extraToolForEachAddlUser" />)
                <br />
                (<Text tid="maxUsers" />
                {config && config.shopMaxValues.plusMaxUsers})
                <br />
                (<Text tid="maxTools" />
                {config && config.shopMaxValues.plusMaxTools})
                <div>
                  <button
                    className="c-btn-medium u-margin-auto u-margin-top-large"
                    onClick={handleLearnMore}
                  >
                    <div className="c-btn__inner">
                      <Text tid="learnMore" />
                    </div>
                  </button>
                </div>
              </div>
              <div className="c-pricing-table__footer c-pricing-table__footer--highlight">
                {(user || shopType) &&
                  <a
                    className="c-btn-full c-pricing-table__btn--highlight"
                    href="#/"
                    onClick={() => handleChangePlan(SHOP_TYPES.PLUS)}
                  >
                    <div className="c-btn__inner">
                      <Text tid="chooseStandardPlus" />
                    </div>
                  </a>
                }
                <Text tid="creditCardOnly" />
              </div>
            </div>
          </div>
          <br />

          {/* AutoAuth Standard */}
          <div className="l-container-center l-container-lg u-margin-top-8">
            <div className="c-card c-pricing-table-card">
              <h2 className="u-text-center l-container-center">
                <Text tid="autoauthStandard" />
                <button
                  className="c-btn-icon u-margin-right"
                  onClick={() => {
                    setTitle('AutoAuth Standard');
                    setDescription(
                      <Text
                        tid="autoAuthStandardOption"
                        sub={{
                          AutoAuthStandard: <strong>AutoAuth Standard</strong>,
                        }}
                      />
                    );
                    setShowDesc(true);
                  }}
                >
                  <div className="c-btn__inner">
                    <i className="c-btn__icon fal fa-question-circle" />
                  </div>
                </button>
              </h2>
              <div className="c-card__description l-container-center">
                <table className="c-pricing-table">
                  <colgroup>
                    <col className="c-pricing-table__column" />
                    <col className="c-pricing-table__column" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th className="c-pricing-table__heading">
                        <Text tid="toolsPerShop" />
                      </th>
                      <td className="c-pricing-table__detail">
                        {config && config.shopBaseValues?.standardBaseTools}
                      </td>
                    </tr>
                    <tr>
                      <th className="c-pricing-table__heading">
                        <Text tid="usersPerShop" />
                      </th>
                      <td className="c-pricing-table__detail">
                        {config && config.shopBaseValues?.standardBaseUsers - 1}
                      </td>
                    </tr>
                    <tr>
                      <th className="c-pricing-table__heading">
                        <Text tid="adminsPerShop" />
                      </th>
                      <td className="c-pricing-table__detail">1</td>
                    </tr>
                    <tr>
                      <th className="c-pricing-table__heading">
                        <Text tid="oemsPerShop" />
                      </th>
                      <td className="c-pricing-table__detail">
                        {config && config.shopBaseValues?.plusBaseOEMRegions}
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading">
                        <Text tid="customNamesForTools" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading">
                        <Text tid="customNotesForTools" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading">
                        <Text tid="shopHistoryReports" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading">
                        <Text tid="technicianHistoryReports" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading">
                        <Text tid="toolHistoryReports" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading">
                        <Text tid="bulkToolImport" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading">
                        <Text tid="bulkTechnicianImport" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading ">
                        <Text tid="customerManagement" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading">
                        <Text tid="vehicleManagement" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading">
                        <Text tid="serviceOrdersManagement" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading">
                        <Text tid="appointmentCalendar" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="c-pricing-table__detail c-pricing-table__detail--big">
                {config && `$${standardMonthly}`}/<Text tid="month" /><br />
                <div className="c-pricing-table__detail--small ">
                  ({config && `$${config.prices.standardShop}.00`} <Text tid="billedYearly" />)
                </div>
              </div>
              <div className="c-pricing-table__detail c-pricing-table__detail--small">
              <Text tid="addlOems" />{' '}
                {config && `$${config.prices.oemRegionAddon}.00`}/
                <Text tid="year" />
                <br />
                (<Text tid="maxUsers" />
                {config && config.shopMaxValues.standardMaxUsers})
                <br />
                (<Text tid="maxTools" />
                {config && config.shopMaxValues.standardMaxTools})
              </div>
              <div className="c-pricing-table__footer">
                {(user || shopType) &&
                  <button
                    className="c-btn-full c-pricing-table__btn"
                    onClick={() => handleChangePlan(SHOP_TYPES.STANDARD)}
                    disabled={isCurrentShopStandard}
                  >
                    <div className="c-btn__inner">
                      <Text tid={isCurrentShopStandard ? "standardCurrentPlan" : "chooseStandard"} />
                    </div>
                  </button>
                }
                <Text tid="creditCardOnly" />
              </div>
            </div>
          </div>
          <br />

          {/* AutoAuth Enterprise Pro */}
          <div className="l-container-center l-container-large u-margin-top-8">
            <div className="c-card c-pricing-table-card">
              <h2 className="u-text-center l-container-center">
                <Text tid="autoauthEnterprisePro" />
                <button
                  className="c-btn-icon u-margin-right"
                  onClick={() => {
                    setTitle('AutoAuth Enterprise Pro');
                    setDescription(
                      <Text
                        tid="autoAuthEnterpriseOption"
                        sub={{
                          AutoAuthEnterprise: (
                            <strong>AutoAuth Enterprise Pro</strong>
                          ),
                        }}
                      />
                    );
                    setShowDesc(true);
                  }}
                >
                  <div className="c-btn__inner">
                    <i className="c-btn__icon fal fa-question-circle" />
                  </div>
                </button>
              </h2>
              <div className="c-card__description l-container-center">
                <table className="c-pricing-table">
                  <colgroup>
                    <col className="c-pricing-table__column" />
                    <col className="c-pricing-table__column" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th className="c-pricing-table__heading">
                        <Text tid="toolsPerShop" />
                      </th>
                      <td className="c-pricing-table__detail">
                        <Text tid="unlimitedTools" />
                      </td>
                    </tr>
                    <tr>
                      <th className="c-pricing-table__heading">
                        <Text tid="usersPerShop" />
                      </th>
                      <td className="c-pricing-table__detail">
                        <Text tid="unlimitedUsers" />
                      </td>
                    </tr>
                    <tr>
                      <th className="c-pricing-table__heading">
                        <Text tid="adminsPerShop" />
                      </th>
                      <td className="c-pricing-table__detail">5</td>
                    </tr>
                    <tr>
                      <th className="c-pricing-table__heading">
                        <Text tid="oemsPerShop" />
                      </th>
                      <td className="c-pricing-table__detail">
                        {config && config.shopBaseValues?.plusBaseOEMRegions}
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading">
                        <Text tid="customNamesForTools" />
                      </th>
                      <td className="c-pricing-table__detail">
                        <i
                          className="fal fa-check-circle"
                          title="fa-check-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading">
                        <Text tid="customNotesForTools" />
                      </th>
                      <td className="c-pricing-table__detail">
                        <i
                          className="fal fa-check-circle"
                          title="fa-check-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading">
                        <Text tid="shopHistoryReports" />
                      </th>
                      <td className="c-pricing-table__detail">
                        <i
                          className="fal fa-check-circle"
                          title="fa-check-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading">
                        <Text tid="technicianHistoryReports" />
                      </th>
                      <td className="c-pricing-table__detail">
                        <i
                          className="fal fa-check-circle"
                          title="fa-check-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading">
                        <Text tid="toolHistoryReports" />
                      </th>
                      <td className="c-pricing-table__detail">
                        <i
                          className="fal fa-check-circle"
                          title="fa-check-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading ">
                        <Text tid="customerManagement" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading">
                        <Text tid="vehicleManagement" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading">
                        <Text tid="serviceOrdersManagement" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading">
                        <Text tid="appointmentCalendar" />
                      </th>
                      <td className="c-pricing-table__detail c-pricing-table__detail--disabled">
                        <i
                          className="fal fa-times-circle"
                          title="fa-times-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading">
                        <Text tid="bulkToolImport" />
                      </th>
                      <td className="c-pricing-table__detail">
                        <i
                          className="fal fa-check-circle"
                          title="fa-check-circle"
                        />
                      </td>
                    </tr>
                    <tr className="c-pricing-table-mobile--small">
                      <th className="c-pricing-table__heading">
                        <Text tid="bulkTechnicianImport" />
                      </th>
                      <td className="c-pricing-table__detail">
                        <i
                          className="fal fa-check-circle"
                          title="fa-check-circle"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="c-pricing-table__detail c-pricing-table__detail--big u-blur">
                $XXX.XX/
                <Text tid="shop" />/<Text tid="year" />
              </div>
              <div className="c-pricing-table__detail c-pricing-table__detail--small u-blur">
                *$XX.XX/
                <Text tid="user" />/<Text tid="year" />
                <br />
                *$XX.XX/
                <Text tid="registeredTool" />/<Text tid="year" />
              </div>
              <div className="c-pricing-table__footer">
                <a
                  className="c-btn-full c-pricing-table__btn"
                  href="https://info.autoauth.com/contact/"
                >
                  <div className="c-btn__inner">
                    <Text tid="contactUs" />
                  </div>
                </a>
                <Text tid="purchaseOrderOnly" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <AlertModal
        showModal={showDesc}
        handleCancel={() => setShowDesc(false)}
        title={title}
        message={description}
      />
    </>
  );
}
