import { get, post } from 'aws-amplify/api';
import { systemInfo } from '../libs/utils';
import { getAuditLogLangPhraseId, trimObjFields } from '../libs/utils-ts';
import { RateLimiter } from 'limiter'
import config from '../config';
import { FullStory } from '@fullstory/browser';
import {AA_ENV} from "../CONSTANTS";
import { TextOnly } from '../components/Text';
import _ from "underscore";

// API Limit is 10 calls per 10s
const limiter = new RateLimiter({ tokensPerInterval: 10, interval: 10000 });
const API_NAME = 'authdiag-dev';
const PATH_PREFIX = '/ui/';

const APIWrap = {
  /**
   * Make a GET request
   * @param {string} apiName  - The api name of the request
   * @param {string} path - The path of the request
   * @param {json} [init] - Request extra params
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  get: async (apiName, path, init) => {
    if (config.AUTH_DIAG_ENVIRONMENT !== AA_ENV.DEV) {
      init.fullstoryLink = FullStory('getSession', { format: 'url' }) || "";
    }
    await limiter.removeTokens(1);
    const restOperation = get({
      apiName,
      path,
      options: init
    });
    const { body } = await restOperation.response;
    return await body.json();
  },
  /**
   * Make a POST request
   * @param {string} apiName  - The api name of the request
   * @param {string} path - The path of the request
   * @param {json} [init] - Request extra params
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  post: async (apiName, path, init) => {
    if (config.AUTH_DIAG_ENVIRONMENT !== AA_ENV.DEV) {
      init.body.fullstoryLink = FullStory('getSession', { format: 'url' }) || "";
    }
    await limiter.removeTokens(1);
    const restOperation = post({
      apiName,
      path,
      options: init
    });
    const { body } = await restOperation.response;
    // This return statement is never reached if the post returned an error,
    // the restOperation.response throws an error instead
    return await body.json();
  },
  /**
   * Make a fetch POST request
   * This is only being used for createUser
   * @param {string} path - The path of the request
   * @param               - action (get or post)
   * @param {json} [init] - Request extra params
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  fetch: async (path, action, init) => {
    if (action === "post") {
      let bodyObj = init;
      if (config.AUTH_DIAG_ENVIRONMENT !== AA_ENV.DEV) {
        bodyObj.fullstoryLink = FullStory('getSession', { format: 'url' }) || "";
      }
      return await fetch(path, {
        method: 'post',
        body: JSON.stringify(bodyObj)
      })
        .then((result) => {
          return result.json();
        })
        .then((response) => {
          if (response?.errorDesc) {
            return response.errorDesc;
          } else {
            if (response && _.keys(response).length > 0) {
              return response;
            } else {
              return null;
            }
          }
        })
        .catch((err) => {
          return err;
        });
    }
  }
};


/**
 * Make am API Request
 * @param {string} method - HTTP method of request (GET, POST)
 * @param {json} body - The body of the request
 * @param {json} pathSuffix - The suffix of the route's path (ex. For "/supportui/getUserSummary", pathSuffix is "getUserSummary")
 * @return {json} - JSON data of the API request, if successful
 */
const apiReq = async (method, body, pathSuffix, customApi) => {

  const apiFunc = method === 'GET' ? APIWrap.get : APIWrap.post;
  const optionsObj = method === 'POST' ?
    {
      response: true,
      body: body,
    } :
    {
      response: true,
    };

  let apiName = API_NAME;
  if (customApi) {
    apiName += '-'+customApi;
  }
  return await apiFunc(apiName, `${PATH_PREFIX}${pathSuffix}`, optionsObj)
  .then((result) => {
    const retVal = {
      data: result
    }
    return retVal;
  })
  .catch(async (error) => {
    const message = error.response.body ? JSON.parse(error.response.body).message : '';
    if (
      error?.response &&
      [401, 403].indexOf(error.response.statusCode) !== -1) {
      if (message.includes('Missing Authentication Token')) {
        console.error('Caught 401/403 error', JSON.stringify(error));
        console.error("error.response: ", JSON.stringify(error.response));
        document.dispatchEvent(
          new CustomEvent('unauthUser', { bubbles: true })
        );
      } else if ((message.includes('Signature expired') && message.includes('5')) ||
                  (message.includes('Signature not yet current')) && message.includes('5')) {
        return { error:  TextOnly("clockSyncError") };
      } else if (message.includes('Signature expired')) {
        console.error('Caught 401/403 error', JSON.stringify(error));
        console.error("error.response: ", JSON.stringify(error.response));
        document.dispatchEvent(
          new CustomEvent('unauthUser', { bubbles: true })
        );
      } else {
        console.log(`1. ${pathSuffix} returned error.`,
        `${method === 'POST' ? 'Body: '+JSON.stringify(body) : ''}`,
        'Error: ', JSON.stringify(error));
        console.error("error.response: ", JSON.stringify(error.response));
        // This if check is for when a post returns an error we have to JSON.parse to find the actual error message
        if (error?.response?.body && typeof error.response.body === 'string') {
          const errorObj = JSON.parse(error?.response?.body);
          return { error: errorObj?.errorDesc ?? error?.message ?? JSON.stringify(error)};
        } else {
          return { error:  error?.response?.data?.errorDesc ?? error?.message ?? JSON.stringify(error) };
        }
      }
    } else {
      const resultBody = error?.response?.body ? JSON.parse(error.response.body) : { errorDesc: 'Unknown Error' };
      return { error: resultBody.errorDesc };

    }
  });
};

const errorRegex = /error/i;

// Enterprise function IDs
const ENTERPRISE_FUNCTION_INVITE_USERS = 1;
const ENTERPRISE_FUNCTION_REVOKE_INVITE = 2;
const ENTERPRISE_FUNCTION_IMPORT_TOOLS = 3;
const ENTERPRISE_FUNCTION_GET_USER_LOG = 4;
const ENTERPRISE_FUNCTION_GET_SHOP_LOG = 5;
const ENTERPRISE_FUNCTION_GET_TOOL_LOG = 6;
const ENTERPRISE_FUNCTION_CREATE_SIGNUP_LINK = 7;
const ENTERPRISE_FUNCTION_UPDATE_SIGNUP_LINK = 8;
const ENTERPRISE_FUNCTION_GET_SIGNUP_LINK_LIST = 9;
const ENTERPRISE_FUNCTION_CREATE_DIRECTORY_INTERFACE = 10;
const ENTERPRISE_FUNCTION_LIST_DIRECTORY_INTERFACES = 11;
const ENTERPRISE_FUNCTION_UPDATE_DIRECTORY_INTERFACE = 12;
const ENTERPRISE_FUNCTION_DELETE_DIRECTORY_INTERFACE = 13;
const ENTERPRISE_FUNCTION_UPGRADE_TO_PLUS = 14;
const ENTERPRISE_FUNCTION_GET_SHOP_STATS = 15;
const ENTERPRISE_FUNCTION_GET_VIN_INFO = 16;
const ENTERPRISE_FUNCTION_GET_SELF_ACTIVITY_LOG = 17;
const ENTERPRISE_FUNCTION_REMOVE_TOOLS = 18;
const ENTERPRISE_FUNCTION_CREATE_DRIVER_VERIFICATION = 19;
const ENTERPRISE_FUNCTION_GET_DRIVER_VERIFICATIONS = 20;

// Unauthorized Function IDs
const UNAUTH_FUNCTION_LOG_SMARTY_STREETS = 2;

export async function getUIStringTableForLanguage(language, forFrontEnd) {
  return await apiReq('GET', {},
  'getUIStringTableForLanguage?languageID=' +
    language +
    '&forFrontEnd=' +
    forFrontEnd
    )
    .then((result) => {
      if(result?.error) return result
      else return result.data;
    });
}

export async function getUserRecord() {
  return await apiReq('GET', {}, 'getUserInfo')
  // It already handles response.data
}

// #region MB CALLS
export async function initializeMessageBoard(shopID) {
  const body = {
    shopID,
  }
  return await apiReq('POST', body, 'initializeMessageBoard', 'mb')
  // It already handles response.data
}

export async function agreeTaC(shopID) {
  const body = {
    shopID,
  }
  return await apiReq('POST', body, 'agreeTaC', 'mb')
  // It already handles response.data
}

export async function getThreadList(
  lastEvaluatedKey,
  sortMethod,
  searchThread,
  shopID,
) {
  const body = {
    sortMethod,
    shopID,
    lastEvaluatedKey: !lastEvaluatedKey ? undefined : lastEvaluatedKey,
    searchThread: !searchThread ? undefined : searchThread,
  }
  return await apiReq('POST', body, 'getThreadList', 'mb')
  .then((result) => {
    if(result?.error) return result
    else return result.data;
  });
}

export async function getThreadSingle(threadID, shopID) {
  const body = {
    threadID,
    shopID,
  }
  return await apiReq('POST', body, 'getThreadSingle', 'mb')
    .then((result)=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function getUserInteraction(
  userName,
  shopID
) {
  const body = {
    userName,
    shopID,
  }
  return await apiReq('POST', body, 'getUserInteraction', 'mb')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function mbStopEmailNotification(
  stopEmailNotification,
  shopID
) {
  const body = {
    stopEmailNotification,
    shopID,
  }
  return await apiReq('POST', body, 'mbStopEmailNotification', 'mb')
}

export async function createThread(
  title,
  tags,
  body,
  shopID
) {
  const bodyAux = {
    title,
    tags,
    body,
    shopID,
  }
  return await apiReq('POST', bodyAux, 'createThread', 'mb')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function createPost(
  body,
  contentID,  // parentInfo
  postID,     // parentInfo
  threadID,   // parentInfo
  contentType,
  shopID,
) {
  const bodyAux = {
    body,
    parentInfo: {
      contentID,
      postID,
      threadID,
    },
    contentType,
    shopID,
  }
  return await apiReq('POST', bodyAux, 'createPost', 'mb')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function createVote(
  contentID,
  postID,
  userRating,
  reportAbuse,
  reportReason,
  contentType,
  shopID,
) {
  const body = {
    contentID,
    postID,
    userRating,
    reportAbuse,
    reportReason,
    contentType,
    shopID,
  }
  return await apiReq('POST', body, 'createVote', 'mb')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function deleteContent(
  contentID,
  postID,
  contentType,
  shopID,
  deleteReason,
) {
  const body = {
    contentID,
    postID,
    contentType,
    shopID,
    deleteReason,
  }
  return await apiReq('POST', body, 'deleteContent', 'mb');
}

export async function featureThread(
  threadID,
  featValue,
  shopID,
) {
  const body = {
    threadID,
    featValue,
    shopID,
  }
  return await apiReq('POST', body, 'featureThread');
}

export async function admoBlocking(userName, newMbRole, shopID) {
  const body = {
    userName,
    newMbRole,
    shopID,
  }
  return await apiReq('POST', body, 'admoBlocking', 'mb')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function admoFunction(
  contentID,
  postID,
  contentType,
  functionType,
  shopID,
  newValue,
) {
  const body = {
    contentID,
    postID,
    contentType,
    functionType,
    shopID,
    newValue,
  }
  return await apiReq('POST', body, 'admoFunction', 'mb')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function admoTreeLock(
  contentID,
  contentType,
  blocked,
  shopID,
) {
  const body = {
    contentID,
    contentType,
    blocked,
    shopID,
  }
  return await apiReq('POST', body, 'admoTreeLock', 'mb');
}
// #endregion

export async function getShopAuditLog(shopID, startDate, endDate) {
  const body = {
    shopID: shopID,
    startDate: startDate,
    endDate: endDate,
    functionID: ENTERPRISE_FUNCTION_GET_SHOP_LOG,
  }
  return await apiReq('POST', body, 'execEnterpriseFunction')
    .then((response) => {
      if(response?.error) return response;
      const result = response.data;
      for (let entry of result['logEntries']) {
        let isReinstatingOems = false;
        let isAddingOems = false;

        if (entry.actionMessage === "User reinstated shop's OEM/Regions marked for removal at next renewal date.") {
          if (entry.reinstatedOems?.length) isReinstatingOems = true;
          if (entry.addedOems?.length) isAddingOems = true;
        }
        entry.isError = errorRegex.test(entry.actionMessage);
        entry.actionMessage = getAuditLogLangPhraseId(entry.actionMessage, isReinstatingOems, isAddingOems);
        entry.apiErrorMessage = getAuditLogLangPhraseId(entry.apiErrorMessage)
          ? getAuditLogLangPhraseId(entry.apiErrorMessage)
          : entry.apiErrorMessage;
      }
      return result;
    });
}

export async function getShopUserAuditLog(userID, shopID, startDate, endDate) {
  const body = {
    userID: userID,
    shopID: shopID,
    startDate: startDate,
    endDate: endDate,
    functionID: ENTERPRISE_FUNCTION_GET_USER_LOG,
  }
  return await apiReq('POST', body, 'execEnterpriseFunction')
    .then((response) => {
      if(response?.error) return response;
      const result = response.data;
      for (let entry of result['logEntries']) {
        let isReinstatingOems = false;
        let isAddingOems = false;

        if (entry.actionMessage === "User reinstated shop's OEM/Regions marked for removal at next renewal date.") {
          if (entry.reinstatedOems?.length) isReinstatingOems = true;
          if (entry.addedOems?.length) isAddingOems = true;
        }
        entry.isError = errorRegex.test(entry.actionMessage);
        entry.actionMessage = getAuditLogLangPhraseId(entry.actionMessage, isReinstatingOems, isAddingOems);
        entry.apiErrorMessage = getAuditLogLangPhraseId(entry.apiErrorMessage)
          ? getAuditLogLangPhraseId(entry.apiErrorMessage)
          : entry.apiErrorMessage;
      }
      return result;
    });
}

export async function getVINDetails(shopID, vins) {
  let vinArr = vins;
  if (typeof vinArr === 'string') {
    vinArr = [vinArr];
  }
  const body = {
    shopID: shopID,
    vins: vinArr,
    functionID: ENTERPRISE_FUNCTION_GET_VIN_INFO,
  }
  return await apiReq('POST', body, 'execEnterpriseFunction')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function getSelfActivityLog(userID, startDate, endDate) {
  const body = {
    userID: userID,
    startDate: startDate,
    endDate: endDate,
    functionID: ENTERPRISE_FUNCTION_GET_SELF_ACTIVITY_LOG,
  }
  return await apiReq('POST', body, 'execEnterpriseFunction')
    .then((response) => {
      if(response?.error) return response;
      const result = response.data;
      for (let entry of result['logEntries']) {
        let isReinstatingOems = false;
        let isAddingOems = false;

        if (entry.actionMessage === "User reinstated shop's OEM/Regions marked for removal at next renewal date.") {
          if (entry.reinstatedOems?.length) isReinstatingOems = true;
          if (entry.addedOems?.length) isAddingOems = true;
        }
        entry.isError = errorRegex.test(entry.actionMessage);
        entry.actionMessage = getAuditLogLangPhraseId(entry.actionMessage, isReinstatingOems, isAddingOems);
        entry.apiErrorMessage = getAuditLogLangPhraseId(entry.apiErrorMessage)
          ? getAuditLogLangPhraseId(entry.apiErrorMessage)
          : entry.apiErrorMessage;
      }
      return result;
    })
}

export async function getShopSignupLinks(shopID) {
  const body = {
    shopID: shopID,
    functionID: ENTERPRISE_FUNCTION_GET_SIGNUP_LINK_LIST,
  }
  return await apiReq('POST', body, 'execEnterpriseFunction')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function getShopStats(shopID, timeGranularity, timeSpecification) {
  const body = {
    timeSpecification,
    timeGranularity,
    shopID: shopID,
    functionID: ENTERPRISE_FUNCTION_GET_SHOP_STATS,
  }
  return await apiReq('POST', body, 'execEnterpriseFunction')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function updateSignupLinkForShop(
  shopID,
  linkID,
  name,
  linkType,
  linkState,
  additionalFields,
  startDate,
  endDate
) {

  let body = {
      shopID: shopID,
      linkID: linkID,
      functionID: ENTERPRISE_FUNCTION_UPDATE_SIGNUP_LINK,
      friendlyName: name,
      additionalIDFields: additionalFields,
      linkState: linkState,
      linkType: linkType,
      startDate: startDate,
      endDate: endDate,
    };

  return await apiReq('POST', body, 'execEnterpriseFunction')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function addShopSignupLink(
  shopID,
  name,
  linkType,
  additionalFields,
  startDate,
  endDate
) {
  let body = {
      shopID: shopID,
      functionID: ENTERPRISE_FUNCTION_CREATE_SIGNUP_LINK,
      friendlyName: name,
      linkType: linkType,
      additionalIDFields: additionalFields,
      startDate: startDate,
      endDate: endDate,
    };

  return await apiReq('POST', body, 'execEnterpriseFunction')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function createDirectoryInterface(
  directoryID,
  shopID,
  directoryType,
  friendlyName,
  tokenIssuerName,
  additionalParams
) {
  let commonParams = {
    directoryID: directoryID,
    shopID: shopID,
    directoryType: directoryType,
    friendlyName: friendlyName,
    tokenIssuerName,
    functionID: ENTERPRISE_FUNCTION_CREATE_DIRECTORY_INTERFACE,
  };
  let body = {
    ...commonParams,
    ...additionalParams,
  };

  return await apiReq('POST', body, 'execEnterpriseFunction')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function getDirectoryInterfaces(shopID) {
  const body = {
    shopID: shopID,
    functionID: ENTERPRISE_FUNCTION_LIST_DIRECTORY_INTERFACES,
  };
  return await apiReq('POST', body, 'execEnterpriseFunction')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function updateDirectoryInterface(
  directoryID,
  shopID,
  directoryType,
  directoryState,
  friendlyName,
  tokenIssuerName,
  additionalParams
) {
  let commonParams = {
    directoryID: directoryID,
    shopID: shopID,
    directoryType: directoryType,
    directoryState: directoryState,
    friendlyName: friendlyName,
    tokenIssuerName: tokenIssuerName,
    functionID: ENTERPRISE_FUNCTION_UPDATE_DIRECTORY_INTERFACE,
  };
  let body = {
    ...commonParams,
    ...additionalParams,
  };
  return await apiReq('POST', body, 'execEnterpriseFunction')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function deleteDirectoryInterface(directoryID, shopID) {
  const body = {
    directoryID: directoryID,
    shopID: shopID,
    functionID: ENTERPRISE_FUNCTION_DELETE_DIRECTORY_INTERFACE,
  };
  return await apiReq('POST', body, 'execEnterpriseFunction')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function getShopToolAuditLog(toolID, shopID, startDate, endDate) {
  const body = {
    toolID: toolID,
    shopID: shopID,
    startDate: startDate,
    endDate: endDate,
    functionID: ENTERPRISE_FUNCTION_GET_TOOL_LOG,
  };
  return await apiReq('POST', body, 'execEnterpriseFunction')
    .then((response) => {
      if(response?.error) return response;
      const result = response.data;
      for (let entry of result['logEntries']) {
        entry['actionMessage'] = getAuditLogLangPhraseId(
          entry['actionMessage']
        );
      }
      return result;
    })
}

export async function getToolTypes() {
  return await apiReq('GET', {},'getToolManufacturerList');
}

export async function createShop(shopName, shopType, paymentInfo, shopInfo) {
  let body = {
      shopName: shopName,
      shopType: shopType,
      paymentInfo: paymentInfo,
      streetAddress1: shopInfo.streetAddress1,
      streetAddress2: shopInfo.streetAddress2,
      city: shopInfo.city,
      state: shopInfo.state,
      zip: shopInfo.zip,
      country: shopInfo.country,
      phone: shopInfo.phone,
      language: window.localStorage.getItem('rcml-lang'),
      oemIDs: shopInfo.oemIDs,
      regionCode: shopInfo.regionCode,
      shareInfo: shopInfo.shareInfo,
    };

  return await apiReq('POST', body, 'createShop');
}

export async function getSystemConfig() {
  return await fetch(config.apiGateway.URL + '/ui/getUIConfigs', {
    response: true,
  })
    .then((result) => {
      return result.json();
    })
    .then((response) => {
      if (response?.errorDesc) {
        return response.errorDesc;
      } else {
        return response;
      }
    })
    .catch((err) => {
      return err;
    });
}

export async function releaseTool(code) {
  return await fetch(config.apiGateway.URL + '/ui/releaseTool/'+code, {
    response: true,
  })
    .then((result) => {
      return result.json();
    })
    .then((response) => {
      if (response?.errorDesc) {
        return response.errorDesc;
      } else {
        return response;
      }
    })
    .catch((err) => {
      return err;
    });
}

export async function getOEMRegionList() {
  const oemRegions = await apiReq('GET', {} ,'getOEMRegionList');
  return oemRegions && oemRegions.data ? oemRegions.data : [];
}

export async function getRegionOemToolCompatibility() {
  return await apiReq('GET', {}, 'getRegionOemToolCompatibility')
}

export async function verifyEmail(code, username, clientId, email, language) {
  return await fetch(
    config.apiGateway.URL +
      '/ui/verifyUserEmail?code=' +
      code +
      '&username=' +
      username +
      '&clientId=' +
      clientId +
      '&email=' +
      email +
      '&language=' +
      language,
    { response: true }
  )
    .then((result) => {
      return result.json();
    })
    .then((response) => {
      if (response?.errorDesc) {
        return { error: response.errorDesc };
      } else {
        return { message: response.message };
      }
    })
    .catch((err) => {
      return err;
    });
}

export async function getSignupCustomFields(linkID) {
  return await fetch(
    config.apiGateway.URL + '/ui/getSignupLinkInfo?linkID=' + linkID,
    { response: true }
  )
    .then((result) => {
      return result.json();
    })
    .then((response) => {
      if (response?.errorDesc) {
        return response.errorDesc;
      } else {
        return response;
      }
    })
    .catch((err) => {
      return err;
    });
}

export async function requestInvite(
  email,
  firstName,
  lastName,
  language,
  inviteType,
  betaCode,
  recaptcha
) {
  return await fetch(config.apiGateway.URL + '/ui/requestInvite', {
    method: 'post',
    body: JSON.stringify({
      email: email,
      firstName: firstName,
      lastName: lastName,
      inviteType: inviteType,
      betaCode: betaCode,
      recaptcha: recaptcha,
      language: language,
    }),
  })
    .then((result) => {
      return result.json();
    })
    .then((response) => {
      if (response?.errorDesc) {
        return response.errorDesc;
      } else {
        return null;
      }
    });
}

export async function createUser(
  username,
  password,
  firstName,
  lastName,
  email,
  language,
  recaptcha,
  inviteCode,
  linkID,
  customFields
) {
  let bodyObj = {
    userName: username,
    password: password,
    firstName: firstName,
    lastName: lastName,
    email: email,
    language: language,
    recaptcha: recaptcha,
    invitationCode: inviteCode,
    linkID: linkID,
    additionalIDFields: customFields,
  };

  return await APIWrap.fetch(config.apiGateway.URL + '/ui/createUser', 'post', bodyObj);
}


export async function updateUser(firstName, lastName, email, language) {
  const body = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    language: language,
  }
  return await apiReq('POST', body, 'updateUserInfo');
}

export async function verifyUser(sessionId) {
  const body = {
    sessionId: sessionId,
  }
  return await apiReq('POST', body, 'updateUserInfo')
  .then(result=>{
    if(result?.error) return result
    else return result.data;
  });
}

export async function checkVerificationSessionStatus(sessionId) {
  const body = {
    processInstanceId: sessionId,
  }
  return await apiReq('POST', body, 'checkVerificationSessionStatus')
  .then(result=>{
    if(result?.error) return result
    else return result.data;
  });
}

export async function updateShopInfo(
  shopID,
  shopName,
  cancelSubscription,
  shopInfo
) {
  let bodyObj = {
    shopID: shopID,
    shopName: shopName,
    statusChange: cancelSubscription ? cancelSubscription : false,
  };
  if (shopInfo) {
    bodyObj.streetAddress1 = shopInfo.streetAddress1;
    bodyObj.streetAddress2 = shopInfo.streetAddress2;
    bodyObj.city = shopInfo.city;
    bodyObj.state = shopInfo.state;
    bodyObj.zip = shopInfo.zip;
    bodyObj.country = shopInfo.country;
    bodyObj.phone = shopInfo.phone;
    bodyObj.shareInfo = shopInfo.shareInfo;
  }
  const body = bodyObj
  return await apiReq('POST', body, 'updateShopInfo');
}

export async function updateShopPaymentInfo(shopId, paymentInfo) {

  const body ={
    shopPaymentInfo: paymentInfo,
    shopID: shopId,
  }
  return await apiReq('POST', body, 'updateShopPaymentInfo')
  .then(result=>{
    if(result?.error) return result
    else return result.data;
  });
}

export async function reinstateNonTerminatedShop(shopId) {

  const body ={
    shopID: shopId,
  }
  return await apiReq('POST', body, 'reinstateNonTerminatedShop')
  .then(result=>{
    if(result?.error) return result
    else return result.data;
  });
}


export async function rechargeDisputedPayment(shopId) {

  const body ={
    shopID: shopId,
  }
  return await apiReq('POST', body, 'rechargeDisputedPayment')
  .then(result=>{
    if(result?.error) return result
    else return result.data;
  });
}

export async function upgradeShopType(shopId, shopType, crmFreeTrial) {
  let action = ENTERPRISE_FUNCTION_UPGRADE_TO_PLUS;

  const body ={
    shopID: shopId,
    functionID: action,
    shopType: shopType,
    crmFreeTrial: crmFreeTrial
  }
  return await apiReq('POST', body, 'execEnterpriseFunction')
    .then((result) => {
      if(result?.error) return result;
      else return result.data;
    })
}

export async function downgradeToStandard(shopId) {

  const body = {
    shopID: shopId,
  }
  return await apiReq('POST', body, 'downgradeToStandard');
}

export async function removeDowngradeToStandard(shopId) {
  const body = {
    shopID: shopId,
  }
  return await apiReq('POST', body, 'removeDowngradeToStandard');
}

export async function addToolForShop(shopId, name="", serialNum, manufacturer, model) {

  const body ={
    shopID: shopId,
    toolName: name,
    toolSerial: serialNum,
    toolManufacturer: manufacturer,
    toolModel: model,
  }
  return await apiReq('POST', body, 'registerToolForShop')
    .then((result) => {
      if(result?.error) return result;
      else return result.data;
    })
}

export async function editToolForShop(shopId, toolId, name, notes) {

  const body ={
  shopID: shopId,
    toolID: toolId,
    toolName: name,
    toolNotes: notes,
  }
  return await apiReq('POST', body, 'editToolForShop')
    .then((result) => {
      if(result?.error) return result;
      else return result.data;
    });
}

export async function importToolsForShop(shopId, toolList) {

  const body = {
    shopID: shopId,
    toolList: toolList,
    functionID: ENTERPRISE_FUNCTION_IMPORT_TOOLS,
  }
  return await apiReq('POST', body, 'execEnterpriseFunction')
  .then((result) => {
    if(result?.error) return result;
    else return result.data;
  });
}

export async function removeToolsForShop(shopId, toolList) {

  const body = {
    shopID: shopId,
    toolList: toolList,
    functionID: ENTERPRISE_FUNCTION_REMOVE_TOOLS,
  }
  return await apiReq('POST', body, 'execEnterpriseFunction')
  .then((result) => {
    if(result?.error) return result;
    else return result.data;
  });
}

export async function importUsersForShop(shopId, userList) {

  const body = {
    shopID: shopId,
    userList: userList,
    functionID: ENTERPRISE_FUNCTION_INVITE_USERS,
  }
  return await apiReq('POST', body, 'execEnterpriseFunction')
  .then((result) => {
    if(result?.error) return result;
    else return result.data;
  });
}

export async function removeToolFromShop(shopId, toolId) {

  const body = {
    shopID: shopId,
    toolID: toolId,
  }
  return await apiReq('POST', body, 'removeToolFromShop');
}

export async function findUser(username) {
  const user = await apiReq('GET', {}, 'findUser?userName=' + username);
  if (user?.error) {
    return user;
  } else {
    const userRec = user && user.data ? user.data : null;
    return {
      firstName: userRec.firstName,
      lastName: userRec.lastName,
      userID: userRec.userID,
      username: username,
    };
  }
}

export async function addUsersToShopCapacity(shopId, numToAdd) {

  const body = {
    shopID: shopId,
    numToAdd: numToAdd,
  }
  return await apiReq('POST', body, 'addShopUserCapacity')
    .then((result) => {
      if(result?.error) return result;
      else return result.data;
    });
}

export async function shopCRMSignup(shopID, giveFreeTrial) {

  const body = {
    shopID,
    giveFreeTrial,
  }
  return await apiReq('POST', body, 'shopCRMSignup', 'crm')
    .then((result) => {
      if(result?.error) return result;
      else return result.data;
    });
}

export async function cancelCRMSubscription(shopID) {
  const body = {
    shopID,
  }
  return await apiReq('POST', body, 'crmCancelCRMSubscription', 'crm')
    .then((result) => {
      if(result?.error) return result;
      else return result.data;
    });
}

export async function reinstateCRMSubscription(shopID) {
  const body = {
    shopID,
  }
  return await apiReq('POST', body, 'reinstateCRMSubscription', 'crm')
    .then((result) => {
      if(result?.error) return result;
      else return result.data;
    });
}

export async function addTechCertsToShopAccount(shopId, numToAdd) {

  const body = {
    shopID: shopId,
    numToAdd: numToAdd,
  }
  return await apiReq('POST', body, 'addTechCertsToShopAccount')
    .then((result) => {
      if(result?.error) return result;
      else return result.data;
    });
}

export async function updateOEMRegions(shopId, regionCode, oemIDs) {

  const body = {
    shopID: shopId,
    regionCode: regionCode,
    oemIDs: oemIDs,
  }
  return await apiReq('POST', body, 'updateShopOEMRegions')
    .then((result) => {
      if(result?.error) return result;
      else return result.data;
    });
}

export async function addReinstateOEMRegions(shopId, regionCode, oemIDs) {

  const body = {
    shopID: shopId,
    regionCode: regionCode,
    oemIDs: oemIDs,
  }
  return await apiReq('POST', body, 'addReinstateShopOEMRegions')
    .then((result) => {
      if(result?.error) return result;
      else return result.data;
    });
}

export async function addUserToShop(shopId, userId, userRole) {

  const body = {
    shopID: shopId,
    userID: userId,
    userRole: userRole,
  }
  return await apiReq('POST', body, 'addUserToShop');
}

export async function removeUserFromShop(shopId, userId) {
  const body = {
    shopID: shopId,
    userID: userId,
  }
  return await apiReq('POST', body, 'removeUserFromShop');
}

export async function revokeInviteFromUserFromShop(shopId, userEmail) {
  const body = {
    shopID: shopId,
    userEmail: userEmail,
    functionID: ENTERPRISE_FUNCTION_REVOKE_INVITE,
  }
  return await apiReq('POST', body, 'execEnterpriseFunction');
}

export async function getUsersForShop(shopId) {
  const body = {
    shopID: shopId
  };

  let results = await apiReq('POST', body, 'getShopUsers');
  let users;
  if (results.data) {
    users = results.data.userList;
  } else {
    return results;
  }

  while (results.data.lastEvaluatedKey) {
    body.lastEvaluatedKey = results.lastEvaluatedKey;
    results = await apiReq('POST', body, 'getShopUsers');
    if (results.data) {
      users = users.concat(results.data.userList);
    } else {
      return results;
    }
  }
  return users;
}

export async function respondToShopInvitation(shopId, response) {
  const body = {
    shopID: shopId,
    response: response,
  }
  return await apiReq('POST', body, 'respondToShopInvitation');
}

export async function changeShopUserState(shopId, userId, newState, newRole) {
  const body = {
    shopID: shopId,
    userID: userId,
    shopUserState: newState,
    shopUserRole: newRole,
  }
  return await apiReq('POST', body, 'setUserStateAtShop');
}

export async function recordUserSession() {
  let sysInfo = systemInfo();
  const body = {
    browserName: sysInfo.browserName,
    browserVersion: String(sysInfo.browserVersion),
    hostOS: sysInfo.hostOS,
  }
  return await apiReq('POST', body, 'recordUserSession');
}

export async function logPasswordResetEmailResend(loginID, functionID) {
  let sysInfo = systemInfo();

  const body = {
    username_email: loginID,
    browserName: sysInfo.browserName,
    browserVersion: String(sysInfo.browserVersion),
    hostOS: sysInfo.hostOS,
    functionID,
  }
  return await apiReq('POST', body, 'execUnauthFunction');
}

export async function logSmartyStreets(
  result,
  selectedAddress
) {
  let sysInfo = systemInfo();

  const body = {
    result,
    inputFields: {},
    apiResponse: {},
    selectedAddress,
    functionID: UNAUTH_FUNCTION_LOG_SMARTY_STREETS,
    browserName: sysInfo.browserName,
    browserVersion: String(sysInfo.browserVersion),
    hostOS: sysInfo.hostOS,
  }
  return await apiReq('POST', body, 'execUnauthFunction');
}

export async function requestToolRecovery(image, serialNum, manufacturer, model, shopID, env) {
  let body = {
    toolSerial: serialNum,
    toolManufacturer: manufacturer,
    toolModel: model,
    shopID: shopID,
    env: env
  }
  return await apiReq('POST', body, 'fetchToolRecoveryURL')
    .then(async (result) => {
      if(result?.error) {
        if(body.docKey){
          const bodyAux = {
            method: 'POST',
            body: body,
          }
          await apiReq('POST', bodyAux, 'removeImage');
        }
        return result
      };
      let response = result?.data;
      body.toolID = response?.get_tool_info?.toolID;
      body.docKey = response?.upload_file?.docKey;
      response = await fetch(response.upload_file.url, {
        method: 'PUT',
        body: image,
      });
      if (response?.status === 401) {
        throw new Error('401 Error');
      } else if (!response?.ok && response?.errorDesc) {
        throw response.errorDesc;
      } else if (!response?.ok && response?.message) {
        throw response.message;
      } else if (!response?.ok) {
        throw new Error(TextOnly('errorToolRecovery'));
      } else {
        return await APIWrap.post('authdiag-dev', '/ui/requestToolRecovery', {
          body: body
        })
      }
    })
}

export async function createVerificationSession(shopID, addRedirectLink) {

  const body = {
    shopID,
    addRedirectLink
  };
  return await apiReq('POST', body, 'createVerificationSession')
  .then((result) => {
    if(result?.error) return result;
    else {
      return result.data;
    }
  });
}

export async function sendPhoneVerificationCode(phoneNumber) {

  const body = {
    phoneNumber: phoneNumber
  };
  return await apiReq('POST', body, 'sendPhoneVerificationCode')
  .then((result) => {
    if(result?.error) return result;
    else return result.data;
  });
}

export async function submitPhoneVerificationCode(code) {

  const body = {
    verificationCode: code
  };
  return await apiReq('POST', body, 'verifyPhoneNumber')
  .then((result) => {
    if(result?.error) return result;
    else return result.data;
  });
}

export async function requestCertification(shopID) {

  const body = {
    shopID: shopID
  };
  return await apiReq('POST', body, 'requestCertification')
  .then((result) => {
    if(result?.error) return result;
    else return result.data;
  });
}

export async function getSingleDriverInfo(queryType, queryValue) {
  const body = {
    queryType,
    queryValue,
  }
  return await apiReq('POST', body, 'getSingleDriverInfo')
  .then((result) => {
    if(result?.error) return result;
    else return result.data;
  });
}

export async function createDriverVerification(
  driverName,
  driverEmail,
  driverPhone,
  vin,
  driverContactPreference,
  vehicleMake,
  vehicleModel,
  vehicleYear,
  servicePerformed,
  shopID,
  vehicleInfo,
  driverInfo,
) {
  const body = {
    driverName,
    driverEmail,
    driverPhone,
    vin,
    driverContactPreference,
    vehicleMake,
    vehicleModel,
    vehicleYear,
    servicePerformed,
    shopID,
    vehicleInfo,
    driverInfo,
    functionID: ENTERPRISE_FUNCTION_CREATE_DRIVER_VERIFICATION,
  }
  return await apiReq('POST', body, 'execEnterpriseFunction')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function getDriverVerificationsForShop(
  shopID,
) {
  const body = {
    shopID,
    functionID: ENTERPRISE_FUNCTION_GET_DRIVER_VERIFICATIONS,
  }
  return await apiReq('POST', body, 'execEnterpriseFunction')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function checkAutoVin(
  vin,
) {
  const body = {
    vin,
  }
  return await apiReq('POST', body, 'checkAutoVin')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function getSingleOwnerVerification(
  shopID,
  verificationID,
) {
  const body = {
    shopID,
    verificationID,
  }
  return await apiReq('POST', body, 'getSingleOwnerVerification')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function updateOwnerVerification(
  vehicleColor,
  vehicleStateRegistration,
  vehicleLPN,
  vehicleOdometer,
  shopID,
  verificationID,
) {
  const body = {
    vehicleColor,
    vehicleStateRegistration,
    vehicleLPN,
    vehicleOdometer,
    shopID,
    verificationID,
  }
  return await apiReq('POST', body, 'updateOwnerVerification')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function rejectVerificationRequest(
  shopID,
  rejecting,
  verificationID,
) {
  const body = {
    shopID,
    rejecting,
    verificationID,
  }
  return await apiReq('POST', body, 'rejectVerificationRequest')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function techSubmitSignatureDate(
  shopID,
  verificationID,
  signatureUrl,
  lsid,
) {
  const body = {
    shopID,
    verificationID,
    signatureUrl,
    lsid,
  }
  return await apiReq('POST', body, 'techSubmitSignatureDate')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function driverSubmitSignatureDate(
  shopID,
  verificationID,
  signatureUrl,
) {
  const body = {
    shopID,
    verificationID,
    signatureUrl,
  }
  return await apiReq('POST', body, 'driverSubmitSignatureDate')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function shopOwnerSubmitSignatureDate(
  shopID,
  verificationID,
  signatureUrl,
) {
  const body = {
    shopID,
    verificationID,
    signatureUrl,
  }
  return await apiReq('POST', body, 'shopOwnerSubmitSignatureDate')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function techGeneratePdfVerification(
  shopID,
  verificationID,
) {
  const body = {
    shopID,
    verificationID,
  }
  return await apiReq('POST', body, 'techGenerateD1Form')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function crmGetShopCustomers(
  shopID,
) {
  const body = {
    shopID,
  }
  return await apiReq('POST', body, 'crmGetShopCustomers', 'crm')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function crmGetShopSingleCustomer(
  shopID,
  customerID,
) {
  const body = {
    shopID,
    customerID,
  }
  return await apiReq('POST', body, 'crmGetShopSingleCustomer', 'crm')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function crmCreateShopCustomer(
  shopID,
  firstName,
  lastName,
  email,
  phoneNumber,
  contactPreference,
  language,
  crmSmsDisclaimer,
  newVehicleInfo,
) {
  const body = {
    shopID,
    firstName,
    lastName,
    email,
    phoneNumber,
    contactPreference,
    language,
    crmSmsDisclaimer,
  }
  if (
    newVehicleInfo.make.length &&
    newVehicleInfo.model.length &&
    newVehicleInfo.year.length
  ) {
    body.newVehicleInfo = newVehicleInfo;
  }
  return await apiReq('POST', body, 'crmCreateShopCustomer', 'crm')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function crmUpdateShopCustomer(
  shopID,
  customerID,
  firstName,
  lastName,
  email,
  phoneNumber,
  contactPreference,
  language,
  crmSmsDisclaimer,
) {
  const body = {
    shopID,
    customerID,
    firstName,
    lastName,
    email,
    phoneNumber,
    contactPreference,
    language,
    crmSmsDisclaimer,
}
  return await apiReq('POST', body, 'crmUpdateShopCustomer', 'crm')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function crmGetShopEvents(
  shopID,
) {
  const body = {
    shopID,
  }
  return await apiReq('POST', body, 'crmGetShopEvents', 'crm')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function crmCreateShopEvent( //
  eventType,
  shopID,
  technicianID,
  orderDetails, // {}
  title,
  allDay,
  description,
  startDate,
  endDate,
  startTime,
  endTime,
  highlighterColor,
) {
  const body = {
    eventType,
    shopID,
    technicianID,
    title,
    allDay,
    description,
    startDate,
    endDate,
    startTime,
    endTime,
    highlighterColor,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }

  // For appointment type only:
  if (orderDetails) {
    // If there is an orderID, orderDetails object is not needed.
    if (orderDetails.orderID) {
      body.orderID = orderDetails.orderID;
    } else {
      body.orderDetails = orderDetails;
    }
  // Backend requires orderID field.
  } else {
    body.orderID = '';
  }
  return await apiReq('POST', body, 'crmCreateShopEvent', 'crm')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function crmUpdateShopEvent(
  eventType,
  shopID,
  eventID,
  technicianID,
  orderDetails, // {}
  title,
  allDay,
  description,
  startDate,
  endDate,
  startTime,
  endTime,
  highlighterColor,
) {

  const body = {
    eventType,
    shopID,
    eventID,
    technicianID,
    title,
    allDay,
    description,
    startDate,
    endDate,
    startTime,
    endTime,
    highlighterColor,
  }

  // For appointment type only:
  if (orderDetails) {
    // If there is an orderID, orderDetails object is not needed.
    if (orderDetails.orderID) {
      body.orderID = orderDetails.orderID;
    } else {
      body.orderDetails = orderDetails;
    }
  // Backend requires orderID field.
  } else {
    body.orderID = '';
  }
  return await apiReq('POST', body, 'crmUpdateShopEvent', 'crm')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function crmDeleteShopEvent(
  shopID,
  eventID,
) {
  const body = {
    shopID,
    eventID,
  }
  return await apiReq('POST', body, 'crmDeleteShopEvent', 'crm')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function crmNotifyCustomerReadyForPickUp(
  shopID,
  eventID,
) {

  const body = {
    shopID,
    eventID,
  }

  return await apiReq('POST', body, 'crmNotifyCustomerReadyForPickUp', 'crm')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function crmRemoveCustomerVehicle(
  shopID,
  customerID,
  vehicleID,
) {
  const body = {
    shopID,
    customerID,
    vehicleID,
  }
  return await apiReq('POST', body, 'crmRemoveCustomerVehicle', 'crm')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function crmGetShopOrders(
  shopID,
) {

  const body = {
    shopID,
  }

  return await apiReq('POST', body, 'crmGetShopOrders', 'crm')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function crmGetShopCustomerOrders(
  shopID,
  customerID,
) {
const body = {
  shopID,
  customerID,
}
return await apiReq('POST', body, 'crmGetShopCustomerOrders', 'crm')
  .then(result=>{
    if (result?.error) return result
      else return result.data;
    });
}

export async function crmGetShopVehicles(
  shopID,
) {

  const body = {
    shopID,
  }

  return await apiReq('POST', body, 'crmGetShopVehicles', 'crm')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function crmCreateShopVehicle(
    shopID,
    customerDetails, // {}
    vehicleVin,
    vehicleMake,
    vehicleModel,
    vehicleYear,
    vehicleColor,
    vehicleOdometer,
    vehicleLPN,
) {
  const body = {
    shopID,
    vehicleVin,
    vehicleMake,
    vehicleModel,
    vehicleYear,
    vehicleColor,
    vehicleOdometer,
    vehicleLPN,
  }

  // If there is a customerID or vehicleID, customerDetails/vehicleDetails object is not needed
  if (customerDetails.customerID) {
    body.customerID = customerDetails.customerID;
  } else {
    body.customerDetails = customerDetails;
  }

  return await apiReq('POST', body, 'crmCreateShopVehicle', 'crm')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function crmUpdateShopVehicle(
    shopID,
    vehicleID,
    vehicleVin,
    vehicleMake,
    vehicleModel,
    vehicleYear,
    vehicleColor,
    vehicleOdometer,
    vehicleLPN,
) {
  const body = {
    shopID,
    vehicleID,
    vehicleVin,
    vehicleMake,
    vehicleModel,
    vehicleYear,
    vehicleColor,
    vehicleOdometer,
    vehicleLPN,
  }
  return await apiReq('POST', body, 'crmUpdateShopVehicle', 'crm')
    .then(result=>{
      if (result?.error) return result
      else return result.data;
    });
}

export async function crmCreateOrder(
  shopID,
  title,
  description,
  status,
  customerDetails, // {}
  vehicleDetails, // {}
  technicianID,
  newEventInfo, // {}
) {
  const body = {
    shopID,
    title,
    description,
    status,
    technicianID,
  }

  if (!!Object.keys(newEventInfo ?? {}).length) {
    body.eventDetails = {
      ...newEventInfo,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
  }

  // If there is a customerID or vehicleID, customerDetails/vehicleDetails object is not needed
  if (customerDetails.customerID) {
    body.customerID = customerDetails.customerID;
  } else {
    body.customerDetails = customerDetails;
  }
  if (vehicleDetails.vehicleID) {
    body.vehicleID = vehicleDetails.vehicleID;
  } else {
    body.vehicleDetails = vehicleDetails;
  }

  return await apiReq('POST', body, 'crmCreateOrder', 'crm')
    .then(result=>{
      if(result?.error) return result
      else return result.data;
    });
}

export async function crmUpdateOrder(
  shopID,
  title,
  description,
  status,
  customerDetails, // {}
  vehicleDetails, // {}
  technicianID,
  orderID,
  newEventInfo, // {}
  sendNotification,
) {
  const body = {
    shopID,
    title,
    description,
    status,
    technicianID,
    orderID,
    sendNotification,
  }

  if (!!Object.keys(newEventInfo ?? {}).length) {
    body.eventDetails = {
      ...newEventInfo,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
  }

  // If there is a customerID or vehicleID, customerDetails object is not needed
  if (customerDetails.customerID) {
    body.customerID = customerDetails.customerID;
  } else {
    body.customerDetails = customerDetails;
  }
  if (vehicleDetails.vehicleID) {
    body.vehicleID = vehicleDetails.vehicleID;
  } else {
    body.vehicleDetails = vehicleDetails;
  }

  return await apiReq('POST', body, 'crmUpdateOrder', 'crm')
    .then(result=>{
      if (result?.error) return result
    else return result.data;
  });
}

export async function crmUpdateOrderStatus(
  shopID,
  orderID,
  status,
  sendNotification,
) {
const body = {
  shopID,
  orderID,
  status,
  sendNotification,
}
return await apiReq('POST', body, 'crmUpdateOrderStatus', 'crm')
  .then(result=>{
    if (result?.error) return result
      else return result.data;
    });
}