import React from 'react';
import { Tooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

interface ToolTipProps {
  text: string | JSX.Element;
  children: React.ReactNode;
}

const ToolTip: React.FC<ToolTipProps> = ({
  text,
  children,
}) => {
  const tooltipId = uuidv4();

  return (
    <>
      <span
        data-tooltip-id={tooltipId}
        data-tool-tip-place={'top'}
        data-tooltip-delay-show={100}
        className="l-flex max-width-fit-content"
      >
        {children}
      </span>
      <div className='tooltip-wrapper'>
        <Tooltip id={tooltipId} place="top" className='normal-tooltip'>
          {text}
        </Tooltip>
      </div>
    </>
  );
};

export default ToolTip;
