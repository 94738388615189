import React, { useEffect, useRef, useState } from 'react';
import { TextOnly, Text } from '../../../components/Text';
import ReactTable from 'react-table';
import EditCustomer from './EditCustomer';
import ViewCustomerOrders from './ViewCustomerOrders';
import HelpTooltip from '../../../components/HelpTooltip';
import AddEditVehicleForm from '../ManageVehicles/AddEditVehicleForm';
import AddCustomer from './AddCustomer';
import { useScreenSize } from '../../../context/ScreenSize';
import { Dialog } from '@reach/dialog';
import { CONTACT_PREFERENCE } from '../CrmConstants';


const CustomerTable: React.FC<any> = (props) => {
  const { isMobile, windowWidth } = useScreenSize();
  const isSmallScreen = isMobile || (windowWidth < 715);
  const { allCustomers, currentShop, user, addNewCustomer, onChange } = props;
  const [filterString, setFilterString] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentCustomer, setCurrentCustomer] = useState<any>(null);
  const [updatedCustomers, setUpatedCustomers] = useState<any[]>([]);
  const customerTableRef = useRef<any>(null);

  useEffect(() => {
    const updatingCustomers = allCustomers.map((customer: any) => {
      const customerName = `${customer.lastName}, ${isSmallScreen ? customer.firstName[0] + '.' : customer.firstName}`
      return {
        customerName,
        ...customer,
      }
    });
    setUpatedCustomers(updatingCustomers);
  }, [allCustomers, isSmallScreen]);

  const handleCancelModal = () => {
    document.querySelector('.c-modal-slider.add-edit-vehicle')?.classList.add('closed');
    setTimeout(() => {
      setShowModal(false);
    }, 350);
  };

  const customerCol = [
    {
      Header: TextOnly('customerName'),
      id: 'customerName',
      accessor: 'customerName',
    },
    {
      Header: TextOnly('contactPreference'),
      accessor: 'contactPreference',
      id: 'contactPreference',
      hide: isSmallScreen,
      width: 90,
      Cell: (row: any) => {
        return (
          <span>
            {CONTACT_PREFERENCE[row.original.contactPreference]}
          </span>
        );
      },
    },
    {
      Header: TextOnly('email'),
      accessor: 'email',
      id: 'email',
      hide: isSmallScreen,
    },
    {
      Header: TextOnly('phoneNumber'),
      accessor: 'phoneNumber',
      id: 'phoneNumber',
      hide: isSmallScreen,
      maxWidth: 150,
    },
    {
      Header: TextOnly('vehicles'),
      accessor: 'vehicles',
      Cell: (row: any) => {
        return (
          <div className="u-width-100-percent">
            {row.original?.vehicles?.map((vehicle: any)=>
              <div key={vehicle.vehicleID}>
                <span>
                  {isSmallScreen ?
                    `${vehicle.year} ${vehicle.model}` :
                    `${vehicle.year} ${vehicle.make} ${vehicle.model}`
                  }
                </span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      Header: TextOnly('actions'),
      accessor: 'actions',
      id: 'actions',
      width: 90,
      Cell: (row: any) => {
        return (
          <div className="actions-wrapper">
            <EditCustomer
              customer={row.original}
              currentShop={currentShop}
              onSubmit={onChange}
            />
            <ViewCustomerOrders
              customer={row.original}
              currentShop={currentShop}
              user={user}
            />
            <HelpTooltip label={TextOnly('addVehicle')}>
              <button
                className="c-btn-icon-compact"
                onClick={() => {
                  setCurrentCustomer(row.original);
                  setShowModal(true);
                }}
              >
                <div className="c-btn__inner">
                  <span className="c-btn__icon fal fa-car" />
                </div>
              </button>
            </HelpTooltip>
          </div>
        );
      },
    },
  ];


  if (customerTableRef.current) {
    if (isSmallScreen) {
      customerTableRef.current.state.SubComponent = (row: any) =>
        <div className='u-margin-bottom'>
          <div className="c-card u-padding-bottom">
            <div className="h3 c-card__title u-margin-none"><Text tid='details' /></div>
            <div className="c-card__description u-margin-none">
              <table className="card-table">
                <tbody>
                  <tr className="card-tr u-width-100-percent display-flex alignitems-baseline">
                    <th className='u-text-left display-flex u-padding-none u-width-30px'>
                      <i className="fa fa-user" aria-hidden="true" />
                    </th>
                    <td className='card-td display-flex'>
                      <span>
                        {row.original.firstName} {row.original.lastName}
                      </span>
                    </td>
                  </tr>
                  <tr className="card-tr u-width-100-percent display-flex alignitems-baseline">
                    <th className='u-text-left display-flex u-padding-none u-width-30px'>
                      <i className="fa fa-envelope" aria-hidden="true" />
                      <span className='u-superscript'>
                        {['EMAIL', 'BOTH'].includes(row.original.contactPreference) ?
                          <i className="fa-solid fa-star" aria-hidden="true" />
                          : <i className="fa-light fa-star" aria-hidden="true" />
                        }
                      </span>
                    </th>
                    <td className='card-td display-flex'>
                      <span className='word-break-break-word'>
                        {row.original.email}
                      </span>
                    </td>
                  </tr>
                  <tr className="card-tr u-width-100-percent display-flex alignitems-baseline">
                    <th className='u-text-left display-flex u-padding-none u-width-30px'>
                      <i className="fa fa-phone" aria-hidden="true" />
                      <span className='u-superscript'>
                        {['PHONE', 'BOTH'].includes(row.original.contactPreference) ?
                          <i className="fa-solid fa-star" aria-hidden="true" />
                          : <i className="fa-light fa-star" aria-hidden="true" />
                        }
                      </span>
                    </th>
                    <td className='card-td display-flex'>
                      <span>
                        {row.original.phoneNumber}
                      </span>
                    </td>
                  </tr>
                  <tr className="card-tr u-width-100-percent display-flex alignitems-baseline">
                    <th className='u-text-left display-flex u-padding-none u-width-30px'>
                      <i className="fa fa-car" aria-hidden="true" />
                    </th>
                    <td className='card-td'>
                      {
                        row.original.vehicles?.map( (vehicle: any) =>
                          <div key={vehicle.vehicleID}>
                            <span>{vehicle.year}&nbsp;{vehicle.make}&nbsp;{vehicle.model}</span>
                          </div>
                        )
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
    } else {
      customerTableRef.current.state.SubComponent = undefined;
    }
  }


  return (
    <div>
      <div className="c-field no-spacing u-width-100-percent u-margin-top-large display-flex flex-wrap-wrap">
        <div className='u-margin-right u-margin-bottom'>
          <AddCustomer
            addNewCustomer={addNewCustomer}
            currentShop={currentShop}
            isSmallScreen={(isSmallScreen && (windowWidth < 600))}
          />
        </div>
        <div className={'display-flex position-relative u-width-100-percent u-min-width-200 u-margin-bottom' + ((windowWidth < 440) ? '' : ' u-max-width-40-percent')}>
          <input
            id="filterActions"
            type="text"
            maxLength={50}
            className="c-input"
            placeholder={TextOnly('searchCustomer') + '...'}
            onChange={(event) => setFilterString(event.target.value)}
            value={filterString}
          />
          <i className="c-field__input-icon fal fa-search" />
        </div>
      </div>
      <ReactTable
        ref={customerTableRef}
        columns={
          customerCol
            .filter(col => !col.hide)
        }
        data={
          updatedCustomers
            .filter((customer: any) =>
              customer?.filterValue?.includes(filterString.toLocaleLowerCase())
            )
        }
        className="-highlight u-margin-top tablelayout"
        previousText={TextOnly('previousPage')}
        nextText={TextOnly('nextPage')}
        pageText={TextOnly('page')}
        ofText={TextOnly('of')}
        rowsText={TextOnly('rows')}
        noDataText={TextOnly('noCustomers')}
        defaultPageSize={5}
        defaultSorted={[
          {
            id: 'customerName',
            desc: false,
          },
        ]}
        getTheadThProps={() => {
          return {
            style: {
              textAlign: 'left',
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'left',
              width: '100%',
            },
          };
        }}
      />
      <Dialog
        isOpen={showModal}
        className="c-modal-slider add-edit-vehicle"
        id="addVehicle"
        onDismiss={handleCancelModal}
        aria-label={TextOnly('addVehicle')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={handleCancelModal}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <div className="c-modal__body">
          <AddEditVehicleForm
            currentShop={currentShop}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            handleCloseModal={handleCancelModal}
            customer={currentCustomer}
            updateSingleCustomer={onChange}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default CustomerTable;
